import Logo from './logo/logo.png';
import LogoV3 from './logo/logo-v3.svg';
import pbaLogo from './logo/pba-logo.svg';

import arrowCircleLeft from './common/left-circle-arrow.png';
import arrowRight from './common/arrow-right.svg';
import arrowCircleRight from './common/right-circle-arrow.png';
import demoNft from './common/nft-img-demo.jpg';
import notFoundNft from './common/not-found-nft.jpg';
import fireIcon from './common/fire-icon.png';
import closeIcon from './common/close-icon.png';
import closeSolidIcon from './common/close-solid.svg';
import mediaIcon from './common/media-icon.svg';
import cartIcon from './common/cart-icon.png';
import emailIcon from './common/email.svg';
import linkInIcon from './common/link-in.svg';
import twIcon from './common/tw.svg';
import twitter from './common/twitter-x.svg';
import facebook from './common/facebook.svg';
import youtube from './common/youtube.svg';
import flobowling from './common/flobowling.svg';
import instagram from './common/instagram.svg';
import whatsAppIcon from './common/whatsapp.svg';
import downloadIcon from './common/download.svg';
import viewIcon from './common/view-icon.png';
import userDefault from './common/user-default.png';
import cameraIcon from './common/cameraIcon.jpg';
import editIcon from './common/editIcon.png';
import arrowDown from './common/arrow-down.png';
import arrowDownSolid from './common/arrow-down-solid.svg';
import zoomIcon from './common/zoomIcon.png';
import arrowUp from './common/arrow-up.png';
import commentIcon from './common/comment.svg';
import questionIcon from './common/question.svg';
import faucet from './pages/home/PF_Faucet-Multipliers.jpg';
import staking from './pages/home/PF_Staking-Multipliers.jpg';
import airdrop from './pages/home/PF_Airdrop-Upgrades.jpg';
import rare from './pages/home/Rare.jpg';
import epic from './pages/home/Epic.jpg';
import boltIcon from './pages/home/bolt-icon.png';
import legendary from './pages/home/Legendary.jpg';
import rewardActive from './pages/home/reward-active.png';
import bannerHomePage from './pages/home/banner.jpg';
import bannerBowling from './pages/home/banner-bowling.jpg';
import new1 from './pages/home/new1.png';
import new2 from './pages/home/new2.jpg';
import new3 from './pages/home/new3.png';
import new4 from './pages/home/new4.png';
import bannerMarketplaceHomePage from './pages/marketplace/banner.jpg';
import bannerDiscount from './pages/nft-item/banner-discount.jpg';
import powerUpIcon from './pages/nft-item/power-up-icon.png';
import noTradingData from './pages/nft-item/no_trading_data.svg';
import bannerHeader from './pages/nft-item/banner-header.jpg';
import bannerActivePack from './pages/power-up/banner-active-pack.png';
import copyIcon from './pages/power-up/copy-icon.png';
import bannerSplashHero from './pages/splash/splash-hero.png';
import leagueCert from './pages/splash/league-cert.png';
import awardsBannerTitle from './pages/splash/splash-banner-title.png';
import awardsBannerTitleMobile from './pages/splash/splash-banner-title-mob.png';
import LCB_DiscoPinEndCard from './pages/splash/LCB_DiscoPinEndCard.png';
import LCB_GoldPinEndCard from './pages/splash/LCB_GoldPinEndCard.png';
import LCB_FishTankPinEndCard from './pages/splash/LCB_FishTankPinEndCard.png';
import LCB_WoodPinEndCard from './pages/splash/LCB_WoodPinEndCard.png';
import LCB_GraffitiPinEndCard from './pages/splash/LCB_GraffitiPinEndCard.png';
import LCB_RetroShirtPinEndCard from './pages/splash/LCB_RetroShirtPinEndCard.png';
import welcomeBanner from './common/welcome.png';
import chevrons from './common/chevrons.svg';

const images = {
  Logo,
  LogoV3,
  pbaLogo,
  common: {
    arrowCircleLeft,
    arrowCircleRight,
    arrowRight,
    demoNft,
    fireIcon,
    boltIcon,
    closeIcon,
    closeSolidIcon,
    mediaIcon,
    notFoundNft,
    cartIcon,
    emailIcon,
    linkInIcon,
    twIcon,
    whatsAppIcon,
    viewIcon,
    userDefault,
    cameraIcon,
    editIcon,
    arrowDown,
    arrowDownSolid,
    copyIcon,
    zoomIcon,
    arrowUp,
    commentIcon,
    questionIcon,
    welcomeBanner,
    chevrons,
  },
  socialIcons: {
    twitter,
    instagram,
    facebook,
    flobowling,
    youtube,
    downloadIcon,
  },
  pages: {
    home: {
      rewardActive,
      bannerHomePage,
      faucet,
      staking,
      airdrop,
      rare,
      epic,
      legendary,
      boltIcon,
      bannerBowling,
      new1,
      new2,
      new3,
      new4,
    },
    marketplace: {
      bannerMarketplaceHomePage,
    },
    nftItem: {
      bannerDiscount,
      powerUpIcon,
      noTradingData,
      bannerHeader,
    },
    powerUp: {
      bannerActivePack,
    },
    splash: {
      bannerSplashHero,
      leagueCert,
      awardsBannerTitle,
      awardsBannerTitleMobile,
      LCB_DiscoPinEndCard,
      LCB_GoldPinEndCard,
      LCB_FishTankPinEndCard,
      LCB_WoodPinEndCard,
      LCB_GraffitiPinEndCard,
      LCB_RetroShirtPinEndCard,
    },
  },
};

export default images;
